/* Updated Styles for footer */
.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-left {
  width: 60%;
  text-align: left;
  margin-top: 20px;
}

.footer-logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-links {
  margin-top: 20px;
}

.footer-link {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
}





/* Footer styling */

.footer-address {
  margin-top: 10px;
  text-align: left;
}

.footer-address a {
  display: block;
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
  transition: text-decoration 0.3s;
}

.footer-address a:hover {
  text-decoration: underline;
}

.footer-email {
  margin-top: 10px;
}

.footer-email a {
  text-decoration: none;
  color: #007bff;
}

.footer-email a:hover {
  text-decoration: underline;
}




/* Custom CSS for LinkedIn and YouTube icons */

.footer-social {
  margin-top: 20px;
}

.footer-icon {
  display: inline-block;
}

.footer-icon img {
  width: 35px;
  height: 24px;
}

.footer-icon:hover img {
  filter: brightness(120%);
}

.linkedin-icon {
  color: #0077B5;
  font-size: 24px;
  margin-right: 10px;
}

.youtube-icon {
  color: #FF0000;
  font-size: 24px;
  margin-right: 10px;
}

.ambition-icon{
    margin-right: 5px;
}

.ambition-icon img{
    width: 35px;
     height: 24px;
}

.github-icon{
 margin-right: 13px;
}

.github-icon img{
    width: 20px;
    height: 20px;
}
.google-icon img{
    width: 20px;
    height: 20px;
}

/* map styling */
.map-container {
  width: 40%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
}

/* Style for the map */
.map {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
