/* Styles for header */

/* Language button styling */
.language-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-right: 10px; /* Adjust margin as needed */
}

.language-button:hover {
  background-color: #333;
  color: #fff;
}

/* Navigation styling */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.brand {
  font-size: 28px;
  font-weight: bold;
  margin-left: 20px;
}

.navbar {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.language-start-container {
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes the language button to the center */
  margin-right: 20px; /* Adjust margin as needed */
}

.nav-link {
  color: #333;
  margin: 0 15px;
  text-decoration: none;
  font-weight: bold;
}

/* Start button styling */
.button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}


/* pop up styling  */

.popup-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.popup-link {
  margin: 10px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.popup-link:hover {
  color: #007bff;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Styles for popup content */
.popup-content {
  background-color: #3333337d;
  padding: 300px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  width: 400px;
  position: relative;
}

/* Close button */
.close-button {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
}